import React from "react";

const RedirectToErrorPage = () => {
  React.useEffect(() => {
    window.location.href = "/error";
  }, []);

  // You can render a fallback UI here or return null if you don't want to render anything
  return null;
};

export default RedirectToErrorPage;

import { gql } from "@apollo/client";

const CREATE_LOG_MUTATION = gql`
  mutation createLogging(
    $projectId: String
    $level: String
    $errorCode: String
    $message: String
    $memberId: String
  ) {
    createLogging(
      input: {
        projectId: $projectId
        level: $level
        errorCode: $errorCode
        message: $message
        memberId: $memberId
      }
    ) {
      logging {
        id
      }
    }
  }
`;

export { CREATE_LOG_MUTATION };

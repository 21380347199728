import React from "react";
import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  VERIFY_PREFIX_PATH,
} from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    // path: `${AUTH_PREFIX_PATH}/login`,
    path: `/:companyId${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-wo-company",
    // path: `${AUTH_PREFIX_PATH}/login`,
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  // {
  //     key: 'register',
  //     path: `${AUTH_PREFIX_PATH}/register`,
  //     component: React.lazy(() => import('views/auth-views/authentication/register')),
  // },
  //   {
  //     key: "forgot-password",
  //     path: `${AUTH_PREFIX_PATH}/forgot-password`,
  //     component: React.lazy(() =>
  //       import("views/auth-views/authentication/forgot-password")
  //     ),
  //   },
  {
    key: "create-account",
    path: `/:companyId${VERIFY_PREFIX_PATH}/project`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/verify/create-account")
    ),
  },
  {
    key: "confirm-account",
    path: `/:companyId${VERIFY_PREFIX_PATH}/confirmAccount`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/verify/confirm-account")
    ),
  },
  {
    key: "initialize-password",
    path: `/:companyId${VERIFY_PREFIX_PATH}/initPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/verify/initialize-password")
    ),
  },
];

export let protectedRoutes = [
  {
    key: "dashboard.home",
    path: `${APP_PREFIX_PATH}/dashboard/home`,
    component: React.lazy(() => import("views/app-views/dashboard/home")),
  },
  {
    key: "dashboard.analytics.default",
    path: `${APP_PREFIX_PATH}/dashboard/analytics/default`,
    component: React.lazy(() =>
      import("views/app-views/dashboard/analytics/default")
    ),
  },
  {
    key: "dashboard.analytics.concurrency",
    path: `${APP_PREFIX_PATH}/dashboard/analytics/concurrency`,
    component: React.lazy(() =>
      import("views/app-views/dashboard/analytics/concurrency")
    ),
  },
  {
    key: "management.user.users",
    path: `${APP_PREFIX_PATH}/management/user/users`,
    component: React.lazy(() =>
      import("views/app-views/management/user/users")
    ),
  },
  {
    key: "management.user.suspended_users",
    path: `${APP_PREFIX_PATH}/management/user/suspended_users`,
    component: React.lazy(() =>
      import("views/app-views/management/user/suspended_users")
    ),
  },
  {
    key: "management.friendship",
    path: `${APP_PREFIX_PATH}/management/friendship`,
    component: React.lazy(() =>
      import("views/app-views/management/friendship")
    ),
  },
  {
    key: "management.chat",
    path: `${APP_PREFIX_PATH}/management/chat/*`,
    component: React.lazy(() => import("views/app-views/management/chat")),
  },
  {
    key: "management.message",
    path: `${APP_PREFIX_PATH}/management/message`,
    component: React.lazy(() => import("views/app-views/management/message")),
  },
  {
    key: "management.archive",
    path: `${APP_PREFIX_PATH}/management/archive`,
    component: React.lazy(() => import("views/app-views/management/archive")),
  },
  {
    key: "management.push_notification",
    path: `${APP_PREFIX_PATH}/management/push_notification`,
    component: React.lazy(() =>
      import("views/app-views/management/push_notification")
    ),
  },
  {
    key: "settings.setting",
    path: `${APP_PREFIX_PATH}/settings/setting/*`,
    component: React.lazy(() => import("views/app-views/settings/setting")),
  },
  {
    key: "help.activity_and_files",
    path: `${APP_PREFIX_PATH}/help/activity_and_files`,
    component: React.lazy(() =>
      import("views/app-views/help/activity_and_files")
    ),
  },
  {
    key: "help.documentation",
    path: `${APP_PREFIX_PATH}/help/documentation`,
    component: React.lazy(() => import("views/app-views/help/documentation")),
  },
  {
    key: "profile",
    path: `${APP_PREFIX_PATH}/profile/*`,
    component: React.lazy(() => import("views/app-views/profile")),
  },
  {
    key: "error",
    path: `/error`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
    meta: {
      blankLayout: true,
    },
  },
];

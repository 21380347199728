import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { onError } from "@apollo/client/link/error";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  MutationCache,
} from "@tanstack/react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone"; // import plugin
import utc from "dayjs/plugin/utc"; // dependent on utc plugin
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { globalMessageHandler } from "utils/globalMessageHandler";

import store, { persistor } from "./store";
import history from "./history";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import { AUTH_TOKEN } from "constants/AuthConstant";
import "./lang";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { signOut } from "store/slices/authSlice";
import "./index.css";
import Utils from "utils";

dayjs.extend(utc); // use plugin
dayjs.extend(timezone);
dayjs.extend(LocalizedFormat);
dayjs.extend(customParseFormat);

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

// link to graphql
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_ENDPOINT_URL}/graphql`,
});

// authorization middleware
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    // const companyId = JSON.parse(localStorage.getItem(USER)).ncpid;
    // const locale = navigator.language;

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
        // ncpid: companyId,
        "accept-language": Utils.getLanguage(),
        language: Utils.getLanguage(),
      },
    };
  });

  return forward(operation);
});

// error handling
// const logoutLink = onError(({ networkError }) => {
//   if (networkError?.statusCode !== 200) {
//     signOut();
//   }
// });
const logoutLink = onError(({ graphQLErrors, networkError }) => {
  
  console.error("graphQLErrors", graphQLErrors);
  console.error("networkError", networkError);
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      globalMessageHandler.error(message);
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    globalMessageHandler.error("Network error, please try again.");
    // navigate(`/${localStorage.getItem("companyId")}/auth/login`);
    // Logout on unauthorized error
    if (networkError.statusCode === 401 || networkError.statusCode === 403) {
      store.dispatch(signOut()); // Ensure you have access to your Redux store to dispatch
    }
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: authMiddleware.concat(logoutLink).concat(httpLink),
  cache: new InMemoryCache({
    resultCaching: false,
  }),
  defaultOptions: defaultOptions,
});

function App() {
  const [messagePopup, messageHolder] = message.useMessage();
  const mutationCache = new MutationCache({
    onError(error) {
      messagePopup.error(
        `Something went wrong: ${Utils.getErrorMessages(error)}`
      );
    },
  });

  const queryCache = new QueryCache({
    onError(error) {
      messagePopup.error(
        `Something went wrong: ${Utils.getErrorMessages(error)}`
      );
    },
  });

  const queryClient = new QueryClient({
    mutationCache,
    queryCache,
    defaultOptions: {
      queries: {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        fetchPolicy: "no-cache",
      },
      mutations: {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        fetchPolicy: "no-cache",
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          {messageHolder}
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter history={history}>
                <ThemeSwitcherProvider
                  themeMap={themes}
                  defaultTheme={THEME_CONFIG.currentTheme}
                  insertionPoint="styles-insertion-point"
                >
                  <Layouts />
                </ThemeSwitcherProvider>
              </BrowserRouter>
            </PersistGate>
          </Provider>
        </div>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

export default App;
export { client };

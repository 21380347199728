import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import Utils from "utils";

export const APP_NAME = (() => {
  switch (process.env.REACT_APP_SOURCE) {
    case "NAVER":
      return "NAVER Cloud Corp.";
    case "NBASE":
      return "NBASE Corp.";
    case "GAMECHAT":
      return "NBASE Corp.";
    default:
      return "Default Corp.";
  }
})();
export const API_BASE_URL = env.API_ENDPOINT_URL;
// export const APP_PREFIX_PATH = `/${Utils.getCompanyId()}/${Utils.getProjectId()}`;

export const APP_PREFIX_PATH = `/:companyId/:projectId`;
export const AUTH_PREFIX_PATH = "/auth";
export const VERIFY_PREFIX_PATH = "/verify";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboard/home`;
export const UNAUTHENTICATED_ENTRY = "/login";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: localStorage.getItem("locale") || "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: localStorage.getItem("currentTheme") || "light",
  direction: DIR_LTR,
  blankLayout: false,
};

import React from "react";
import { Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { APP_NAME } from "configs/AppConfig";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import IntlMessage from "components/util-components/IntlMessage";
import Flex from "components/shared-components/Flex";
import useCreateLog from "utils/hooks/useCreateLog";

const ErrorOne = () => {
  const createLog = useCreateLog();
  const theme = useSelector((state) => state.theme.currentTheme);
  const navigate = useNavigate();
  const logo =
  process.env.REACT_APP_SOURCE === "NAVER"
    ? "naver-logo"
    : process.env.REACT_APP_SOURCE === "GAMECHAT"
    ? "gamechat-logo"
    : "logo";

  React.useEffect(() => {
    const handleSomeAction = async () => {
      // ... some logic ...
      await createLog({
        level: "info",
        errorCode: "some-error-code",
        message: "This is a log message",
        memberId: "user-id",
      });
    };
    handleSomeAction();
  }, []);

  return (
    <div className={`h-100 ${theme === "light" ? "bg-white" : ""}`}>
      <div className="container-fluid d-flex flex-column justify-content-between h-100 px-4 pb-4 pt-3">
        <div>
          <img
            className="img-fluid"
            src={`/img/${logo}.png`}
            alt={`${APP_NAME} logo`}
          />
        </div>
        <div className="container">
          <Row align="middle">
            <Col xs={24} sm={24} md={8}>
              <h1 className="font-weight-bold mb-4 display-4">
                <IntlMessage id="Page not found" />
              </h1>
              <p className="font-size-md mb-4">
                <IntlMessage id="We've noticed you lost your way, no worries, we will help you to found the correct path." />
              </p>
              <Button
                type="primary"
                icon={<ArrowLeftOutlined className="mr-1" />}
                onClick={() => navigate(-1)}
              >
                <IntlMessage id="Go back" />
              </Button>
            </Col>
            <Col xs={24} sm={24} md={{ span: 14, offset: 2 }}>
              <img
                className="img-fluid mt-md-0 mt-4"
                src="/img/others/img-20.png"
                alt="404 Not Found"
              />
            </Col>
          </Row>
        </div>
        <Flex justifyContent="space-between">
          <span>
            Copyright &copy; {`${new Date().getFullYear()}`}{" "}
            <span className="font-weight-semibold">{`${APP_NAME}`}</span> All
            rights reserved.
          </span>
          <div>
            <a
              className="text-gray"
              href="https://www.ncloud.com/policy/terms/svc"
            >
              <IntlMessage id="Term & Conditions" />
            </a>
            <span className="mx-2 text-muted"> | </span>
            <a
              className="text-gray"
              href="https://www.ncloud.com/policy/infou/infou"
            >
              <IntlMessage id="Privacy & Policy" />
            </a>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default ErrorOne;

import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.login = function ({ data, language }) {
  return fetch({
    url: `${process.env.REACT_APP_API_ENDPOINT_URL}/v1/auth/signin`,
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "application/json",
      "accept-language": language,
    },
    data: data,
  });
};

AuthService.register = function (data) {
  return fetch({
    url: "/auth/register",
    method: "post",
    data: data,
  });
};

AuthService.logout = function () {
  fetch({
    url: `${process.env.REACT_APP_API_ENDPOINT_URL}/v1/auth/signout`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "accept-language": navigator.language,
    },
  });
};

AuthService.loginInOAuth = function () {
  return fetch({
    url: "/auth/loginInOAuth",
    method: "post",
  });
};

export default AuthService;

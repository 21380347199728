import { useMutation } from "@apollo/client";

import { CREATE_LOG_MUTATION } from "graphql/mutation/log";

const useCreateLog = () => {
  const [createLogMutation] = useMutation(CREATE_LOG_MUTATION, {
    onError: (error) => {},
  });

  const createLog = async (logData) => {
    try {
      const response = await createLogMutation({
        variables: {
          projectId: logData.projectId,
          level: logData.level,
          errorCode: logData.errorCode,
          message: logData.message,
          memberId: logData.memberId,
        },
      });
      // Handle the response as needed
      return response;
    } catch (error) {
      // Handle errors
      console.error("Error creating log:", error);
    }
  };

  return createLog;
};

export default useCreateLog;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isConnected: false,
  channels: null,
  me: null,
};

const cloudchatSlice = createSlice({
  name: "cloudchatSlice",
  initialState,
  reducers: {
    setIsConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    setChannels: (state, action) => {
      state.channels = action.payload;
    },
    setMe: (state, action) => {
      state.me = action.payload;
    },
  },
});

export const { setIsConnected, setChannels, setMe } = cloudchatSlice.actions;

export default cloudchatSlice.reducer;

import { message } from "antd";

const themeStyles = {
  dark: {
    backgroundColor: "#333", // Example dark theme styles
    color: "#fff",
  },
  light: {
    backgroundColor: "#fff", // Example light theme styles
    color: "#000",
  },
};

export const globalMessageHandler = {
  error: (msg) => {
    const currentTheme = localStorage.getItem("currentTheme") || "light";
    const style = themeStyles[currentTheme];
    const styleContent =
      currentTheme === "dark"
        ? `.ant-message-notice-content { background-color: #333 !important; }`
        : `.ant-message-notice-content { background-color: #fff !important; }`;
    let styleTag = document.getElementById("ant-message-notice-content");
    if (!styleTag) {
      // If not, create one
      styleTag = document.createElement("style");
      styleTag.id = "ant-message-notice-content";
      document.head.appendChild(styleTag);
    }
    // Update or set the style content
    styleTag.innerHTML = styleContent;

    message.error({
      content: msg,
      duration: 3,
      key: "error",
      style: {
        color: style.color, // Apply text color based on theme
      },
    });
  },
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import AuthService from "services/AuthService";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// Assuming the URL is http://example.com/firstParam/secondParam
const currentUrl = window.location.href; // gets the full URL
const url = new URL(currentUrl);
const pathSegments = url.pathname.split("/"); // splits the path by "/"

// Getting the first parameter from the path
// The first element in the array is an empty string before the first "/", so the second element is the first parameter
const firstParam = pathSegments[1];

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  // token: localStorage.getItem(AUTH_TOKEN) || null,
  token:
    localStorage.getItem("companyId") === firstParam
      ? localStorage.getItem(AUTH_TOKEN)
      : null,
  user: null,
  menu: [],
};

export const signIn = createAsyncThunk(
  "/auth/login",
  async (data, { rejectWithValue }) => {
    const { email, password, company_id, locale } = data;
    localStorage.setItem("companyId", company_id);
    const response = await AuthService.login({
      data: {
        username: email,
        password,
        ncpid: company_id,
      },
      language: locale,
    });

    if (response?.status) {
      const token = response.token;
      localStorage.setItem(AUTH_TOKEN, token);
      window.location.href = `/${company_id}/${response.project_id}/dashboard/home`;
      return response;
    } else {
      localStorage.removeItem("companyId");
      if (response?.code === -4444) {
        return rejectWithValue(response.error);
      } else {
        return rejectWithValue(response.message || "Error");
      }
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await AuthService.register({ email, password });
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  const response = await AuthService.logout();
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem("companyId");
  return response;
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = `/${localStorage.getItem("companyId")}`;
      state.user = null;
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.user = action.payload;
        state.menu = action.payload.menu;
        state.token = action.payload.token;
      })
      .addCase(signIn.rejected, (state, action) => {

        state.message = action.payload;
        // state.message = action.Utils.getErrorMessages(error);
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
        state.user = null;
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

const authPersistConfig = {
  key: "auth", // key for localStorage key, you might want to namespace it like 'myAppAuth'
  storage,
  whitelist: ["menu"], // You can add other state parts here if needed
};

export const persistedAuthReducer = persistReducer(
  authPersistConfig,
  authSlice.reducer
);

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  setMenu,
} = authSlice.actions;

// export default authSlice.reducer;
export default persistedAuthReducer;

import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const initialState = {
  currentProject: null,
};

const projectSlice = createSlice({
  name: "projectSlice",
  initialState,
  reducers: {
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
  },
});

const persistConfig = {
  key: "project",
  storage,
  whitelist: ["currentProject"], // only persist currentProject
};

const persistedReducer = persistReducer(persistConfig, projectSlice.reducer);

export const { setCurrentProject } = projectSlice.actions;

// export default projectSlice.reducer;
export default persistedReducer;
